import PresentationCarousel from "./PresentationCarousel";
import Presentation from "./Presentation";
import AnimationButton from "../AnimationButton";
import Contact from "./Contact";
import Partenaire from "./Partenaire";

function PresentationPage() {
    return (
        <div>
            <AnimationButton/>
            <PresentationCarousel/>
            <Contact/>
            <Presentation/>
            <Partenaire/>
        </div>
    )
}

export default PresentationPage;
