import {abis, addresses} from "@project/contracts";
import Contract from "web3-eth-contract";

let GContract;

function setProvider(provider) {
    Contract.setProvider(provider);
    GContract = new Contract(abis.game, addresses.Game)
}

async function getClaimFee(account) {
    return await GContract.methods.getClaimFee().call({from: account});
}

async function getClaimCooldown(account) {
    return await GContract.methods.getClaimCooldown().call({from: account});
}

async function getRewards(account) {
    return await GContract.methods.getRewards().call({from: account});
}

async function isLevelUpOpen() {
    return await GContract.methods.levelUpOpen().call();
}

async function isTrainingOpen() {
    return await GContract.methods.trainingOpen().call();
}

async function isUpgradeFrameOpen() {
    return await GContract.methods.upgradeFrameOpen().call();
}

async function getRemainingClaimCooldown(account) {
    return await GContract.methods.getRemainingClaimCooldown().call({from: account});
}

function getCurrentStamina(playerId) {
    return GContract.methods.getCurrentStamina(playerId).call();
}

function getXpRequireToLvlUp(score) {
    return score * (score / 2);
}

async function getFootballTokenPrice() {
    return await GContract.methods.getFootballTokenPrice().call();
}

function getContract() {
    return GContract;
}

export default { getRewards, getClaimFee, getClaimCooldown, isLevelUpOpen, isTrainingOpen, isUpgradeFrameOpen, getRemainingClaimCooldown, getCurrentStamina, getXpRequireToLvlUp, setProvider, getFootballTokenPrice, getContract };