// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
const addresses = {
    BUSDTestnet: "0x78867bbeef44f2326bf8ddd1941a4439382ef2a7",
    BUSD: "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee",
    GBTOKEN: "0x9c919E7b3cE2623F4725ab4b919eAb737f6Cc176",
    Marketplace: "0xBAfC64a490623c46273a11bA04b438777C7B7450",
    Game: "0x9628B6Db15E85F3bB8b983c10b06D5d07534eE88",
    FootballPlayers: "0xd910577c16ABE09830C75d35Db17bb3CcF9825aD",//0x570D978BFf3B7dDc6eEBbCaf72Be9e8ACcaC0CD1
    FootballHeroesStorage: "0x10b7160e9e6c6dd6B7DfeA4dFddF4ACfC3E5a6E3"
};

export default addresses;
